<template>
  <div class="onboarding-landing p-1">
    <div class="d-flex justify-content-between">
      <div>
        <b-img
          src="../../../assets/images/logo/starworks.png"
          style="height: 40px;"
        />
      </div>
      <div>
        <div
          v-if="currentdomain==='impunjab'"
          class="d-flex align-items-center justify-content-center"
        >
          <b-img
            src="../../../assets/images/logo/imp.png"
            style="height: 40px;"
          />
        </div>
        <div
          v-else-if="currentdomain==='ginserv'"
          class="d-flex align-items-center justify-content-center"
        >
          <b-img
            src="../../../assets/images/logo/ginserv.png"
            style="height: 40px;"
          />
        </div>
        <div
          v-else-if="currentdomain==='aghub'"
          class="d-flex align-items-center justify-content-center"
        >
          <b-img
            src="../../../assets/images/logo/aghub.png"
            style="height: 40px;"
          />
        </div>
        <div
          v-else-if="currentdomain==='actionplaniitb'"
          class="d-flex align-items-center justify-content-center"
        >
          <b-img
            src="../../../assets/images/logo/abhyuday.png"
            style="height: 40px;"
          />
        </div>
        <div
          v-else-if="currentdomain==='aic-prestige'"
          class="d-flex align-items-center justify-content-center"
        >
          <b-img
            src="../../../assets/images/logo/aic-prestige.png"
            style="height: 40px;"
          />
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <b-card class="onboarding-card">
        <h1 />
        <validation-observer
          ref="registerForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-register-form mt-2"
            @submit.prevent="completeProfile"
          >

            <div
              v-if="formstate>=1 && formstate<=7"
              class="dotsholder mb-4"
            >
              <div class="dotbox">
                <div class="dotwrapper">
                  <div
                    :class="`dot ${formstate>=1?'active':''}`"
                    @click="changestatus(1)"
                  >
                    1
                  </div>
                  <div class="backlineholderr">
                    <div :class="`backliner ${formstate>=2?'active':''}`" />
                  </div>
                </div>
                <!-- <b-card-text class="text-center text-sm" style="color:#6e6b7b">Text</b-card-text> -->
              </div>
              <div class="dotbox">
                <div class="dotwrapper">
                  <div
                    :class="`dot ${formstate>=2?'active':''}`"
                    @click="changestatus(2)"
                  >
                    2
                  </div>
                  <div class="backlineholderl">
                    <div :class="`backlinel ${formstate>=2?'active':''}`" />
                  </div>
                  <div class="backlineholderr">
                    <div :class="`backliner ${formstate>=3?'active':''}`" />
                  </div>
                </div>
                <!-- <b-card-text class="text-center text-sm" style="color:#6e6b7b">Text</b-card-text> -->
              </div>
              <div class="dotbox">
                <div class="dotwrapper">
                  <div
                    :class="`dot ${formstate>=3?'active':''}`"
                    @click="changestatus(3)"
                  >
                    3
                  </div>
                  <div class="backlineholderl">
                    <div :class="`backlinel ${formstate>=3?'active':''}`" />
                  </div>
                </div>
                <!-- <b-card-text class="text-center text-sm" style="color:#6e6b7b">Text</b-card-text> -->
              </div>
            </div>

            <div :class="formstate==1 ? '' : 'd-none'">
              <h6 style="font-weight: bolder;">
                Select Account Type
              </h6>
              <b-row class="mb-1">
                <b-col xs="3">
                  <div
                    :class="`role-card pt-1 ${accrole=='Startup'?'active':''}`"
                    @click="setrole('Startup')"
                  >
                    <div class="rol-img">
                      <feather-icon
                        icon="StarIcon"
                        size="30"
                      />
                    </div>
                    <h6
                      class="text-center"
                      style="padding-top:0.5rem;padding-bottom:0.5rem;color:inherit;"
                    >
                      Startup
                    </h6>
                  </div>
                </b-col>
                <b-col xs="3">
                  <div
                    :class="`role-card pt-1 ${accrole=='Student'?'active':''}`"
                    @click="setrole('Student')"
                  >
                    <div class="rol-img">
                      <feather-icon
                        icon="BookIcon"
                        size="30"
                      />
                    </div>
                    <h6
                      class="text-center"
                      style="padding-top:0.5rem;padding-bottom:0.5rem;color:inherit;"
                    >
                      Student
                    </h6>
                  </div>
                </b-col>
                <b-col xs="3">
                  <div
                    :class="`role-card pt-1 ${accrole=='Mentor'?'active':''}`"
                    @click="setrole('Mentor')"
                  >
                    <div class="rol-img">
                      <feather-icon
                        icon="UserIcon"
                        size="30"
                      />
                    </div>
                    <h6
                      class="text-center"
                      style="padding-top:0.5rem;padding-bottom:0.5rem;color:inherit;"
                    >
                      Mentor
                    </h6>
                  </div>
                </b-col>
                <b-col xs="3">
                  <div
                    :class="`role-card pt-1 ${accrole=='Partner'?'active':''}`"
                    @click="setrole('Partner')"
                  >
                    <div class="rol-img">
                      <feather-icon
                        icon="BookIcon"
                        size="30"
                      />
                    </div>
                    <h6
                      class="text-center"
                      style="padding-top:0.5rem;padding-bottom:0.5rem;color:inherit;"
                    >
                      Partner
                    </h6>
                  </div>
                </b-col>
              </b-row>
              <b-form-group
                :class="`${formstate==1 && accrole && (accrole=='Startup' || accrole=='Partner') ? '' : 'd-none'}`"
                :label="accrole=='Startup' ? 'Startup Name' : 'Organization Name'"
                label-for="startup-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required"
                  vid="name"
                >
                  <b-form-input
                    id="startup-name"
                    v-model="startupname"
                    :state="errors.length > 0 ? false:null"
                    name="startup-name"
                    placeholder="Enter your name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                :class="`${formstate==1 && accrole && (accrole=='Startup' || accrole=='Partner') ? '' : 'd-none'}`"
                label="Designation"
                label-for="designation"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required"
                  vid="name"
                >
                  <b-form-input
                    id="designation"
                    v-model="designation"
                    :state="errors.length > 0 ? false:null"
                    name="designation"
                    placeholder="Enter your designation"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                :class="`${formstate==1 && accrole ? '' : 'd-none'}`"
                label="About"
                label-for="designation"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required"
                  vid="name"
                >
                  <b-form-textarea
                    id="designation"
                    v-model="about"
                    :state="errors.length > 0 ? false:null"
                    name="designation"
                    placeholder="Enter your name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div :class="`${formstate==2 ? '' : 'd-none'}`">
              <h6 style="font-weight:bolder;">
                <span
                  v-if="accrole=='Startup'"
                >Select Relevant Options for Your Startup</span>
                <span
                  v-else
                >Select Interest Areas</span>
              </h6>
              <b-form-group
                label="Select Industry"
                label-for="industry"
              >
                <b-row>
                  <b-col
                    v-for="(option, oi) in industryoptions"
                    :key="oi"
                    lg="2"
                    md="3"
                    sm="6"
                  >
                    <div
                      :class="`${industry.indexOf(option) >= 0 ? 'category-card-active' : 'category-card-inactive'} px-1 py-50 mb-1`"
                      @click="handleIndustryOption(option);"
                    >
                      {{ option }}
                    </div>
                  </b-col>
                </b-row>
              </b-form-group>
            </div>
            <div :class="`${formstate==2 && industry.length > 0 ? '' : 'd-none'}`">
              <b-form-group
                label="Select Lifecycle"
                label-for="lifecycle"
              >
                <b-row>
                  <b-col
                    v-for="(option, oi) in lifecycleoptions"
                    :key="oi"
                    lg="2"
                    md="3"
                    sm="6"
                  >
                    <div
                      :class="`${lifecycle.indexOf(option) >= 0 ? 'category-card-active' : 'category-card-inactive'} px-1 py-50 mb-1`"
                      @click="handleLifecycleOption(option);"
                    >
                      {{ option }}
                    </div>
                  </b-col>
                </b-row>
              </b-form-group>
            </div>
            <div :class="`${formstate==3 ? '' : 'd-none'}`">
              <b-form-group
                label="Select Customer Type"
                label-for="customertype"
              >
                <b-row>
                  <b-col
                    v-for="(option, oi) in customertypeoptions"
                    :key="oi"
                    lg="2"
                    md="3"
                    sm="6"
                  >
                    <div
                      :class="`${customertype.indexOf(option) >= 0 ? 'category-card-active' : 'category-card-inactive'} px-1 py-50 mb-1`"
                      @click="handleCustomerTypeOption(option);"
                    >
                      {{ option }}
                    </div>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group
                :class="`${customertype.length > 0 ? '' : 'd-none'}`"
                label="Select Offering Type"
                label-for="offeringtype"
              >
                <b-row>
                  <b-col
                    v-for="(option, oi) in offeringtypeoptions"
                    :key="oi"
                    lg="2"
                    md="3"
                    sm="6"
                  >
                    <div
                      :class="`${offeringtype.indexOf(option) >= 0 ? 'category-card-active' : 'category-card-inactive'} px-1 py-50 mb-1`"
                      @click="handleOfferingTypeOption(option);"
                    >
                      {{ option }}
                    </div>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group
                :class="`${offeringtype.length > 0 ? '' : 'd-none'}`"
                label="Select Focus Area"
                label-for="focusarea"
              >
                <b-row>
                  <b-col
                    v-for="(option, oi) in focusareaoptions"
                    :key="oi"
                    lg="2"
                    md="3"
                    sm="6"
                  >
                    <div
                      :class="`${focusarea.indexOf(option) >= 0 ? 'category-card-active' : 'category-card-inactive'} px-1 py-50 mb-1`"
                      @click="handleFocusAreaOption(option);"
                    >
                      {{ option }}
                    </div>
                  </b-col>
                </b-row>
              </b-form-group>
            </div>

            <b-button
              :disabled="invalid || loading || (formstate === 1 && !about) || (formstate === 2 && lifecycle.length === 0) || (formstate === 3 && focusarea.length === 0)"
              block
              :type="formstate==3?'submit':'button'"
              variant="primary"
              @click="nextstate"
            >
              <b-spinner
                v-if="loading && formstate==3"
                small
              />
              <span
                v-if="formstate==3"
                v-text="'Complete Profile'"
              />
              <span v-else>
                Next
              </span>
            </b-button>
            <!-- <b-form-group
              label="Register as"
              label-cols="6"
              label-cols-lg="4"
              label-for="role"
            >
              <validation-provider
                #default="{ errors }"
                name="Role"
                rules="required"
                vid="role"
              >
                <b-form-select
                  id="role"
                  v-model="role"
                  :options="[{ value: null, text: 'Please select a role', disabled: true }, {text: 'Startup or Student', value: 'user'}, {text: 'Mentor or Partner', value: 'mentor'}]"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group> -->

            <!-- first name -->

          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BCard,
  BButton,
  // BCardText,
  // BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BImg,
  // BInputGroup,
  // BInputGroupAppend,
  // BLink,
  // BOverlay,
  // BRow,
  // BSpinner,
  VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'
import { email, required } from '@validations'
// import { togglePasswordVisibility } from '@core/mixins/ui/forms'
// import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import gql from 'graphql-tag'
// import jwtDecode from 'jwt-decode'
import { Auth } from 'aws-amplify'
import { getUserData } from '@/auth/utils'
import axios from 'axios'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    // BSpinner,
    // BRow,
    // BCol,
    // BLink,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    // BInputGroupAppend,
    // BInputGroup,
    // BOverlay,
    // BCardText,
    BImg,
    BCard,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      currentdomain: '',
      loading: false,
      resetCodeSent: false,
      status: '',
      password: '',
      userEmail: '',
      full_name: '',
      startupname: '',
      designation: '',
      role: null,
      accrole: null,
      about: '',
      country: '',
      state: '',
      resetCode: '',
      incubator: [],
      incubatorname: 'Starworks Prime',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      randomFact: '',
      // validation rules
      required,
      email,
      userInfo: [],
      formstate: 1,
      industry: [],
      lifecycle: [],
      customertype: [],
      offeringtype: [],
      focusarea: [],
      industryoptions: ['Agriculture and Allied Industries',
        'Automobiles',
        'Auto Components',
        'Aviation',
        'Banking',
        'Biotechnology',
        'Cement',
        'Chemicals',
        'Consumer Durables',
        'Defence Manufacturing',
        'E-Commerce',
        'Education and Training',
        'Electronics System Design and Manufacturing',
        'Engineering and Capital Goods',
        'Financial Services',
        'FMCG',
        'Gems and Jewellery',
        'Healthcare',
        'Infrastructure',
        'Insurance',
        'IT and BPM',
        'Manufacturing',
        'Media and Entertainment',
        'Medical Devices',
        'Metals and Mining',
        'MSME',
        'Oil and Gas',
        'Pharmaceuticals',
        'Ports',
        'Power',
        'Railways',
        'Real Estate',
        'Renewable Energy',
        'Retail',
        'Roads',
        'Science and Technology',
        'Services',
        'Steel',
        'Telecommunications',
        'Textiles',
        'Tourism and Hospitality'],
      lifecycleoptions: ['Ideation', 'Business Modelling', 'Prototyping', 'MVP', 'Market Traction', 'Product Introduction', 'Revenue Generation', 'Scaling'],
      customertypeoptions: ['B2C',
        'B2B',
        'B2G',
        'D2C',
        'B2B2C',
        'B2B2B',
        'B2B2G',
        'B2G2C',
        'B2G2B',
        'B2C2C',
        'B2C2B',
        'B2C2G'],
      offeringtypeoptions: ['Product',
        'Service',
        'Hybrid'],
      focusareaoptions: ['Profit',
        'Social Impact',
        'Environmental Impact'],
    }
  },
  computed: {
    // imgUrl() {
    //   if (store.state.appConfig.layout.skin === 'dark') {
    //     // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    //     this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
    //     return this.sideImg
    //   }
    //   return this.sideImg
    // },
  },
  beforeMount() {
    this.getHost()
  },
  mounted() {
    this.checkAuthState()
  },
  methods: {
    async checkAuthState() {
      if (!getUserData()) {
        window.location.href = '/'
      }
    },
    getHost() {
      this.currentdomain = window.location.host.split('.')[0].toString()
    },
    setrole(newrole) {
      this.accrole = newrole
    },
    handleIndustryOption(op) {
      if (this.industry.indexOf(op) >= 0) {
        const newindustry = this.industry.splice(this.industry.indexOf(op), 1)
        this.industry = newindustry
      } else {
        const newindustry = this.industry
        newindustry.push(op)
        this.industry = newindustry
      }
    },
    handleLifecycleOption(op) {
      if (this.lifecycle.indexOf(op) >= 0) {
        const newlifecycle = this.lifecycle.splice(this.lifecycle.indexOf(op), 1)
        this.lifecycle = newlifecycle
      } else {
        const newlifecycle = this.lifecycle
        newlifecycle.push(op)
        this.lifecycle = newlifecycle
      }
    },
    handleCustomerTypeOption(op) {
      if (this.customertype.indexOf(op) >= 0) {
        const newcustomertype = this.customertype.splice(this.customertype.indexOf(op), 1)
        this.customertype = newcustomertype
      } else {
        const newcustomertype = this.customertype
        newcustomertype.push(op)
        this.customertype = newcustomertype
      }
    },
    handleOfferingTypeOption(op) {
      if (this.offeringtype.indexOf(op) >= 0) {
        const newofferingtype = this.offeringtype.splice(this.offeringtype.indexOf(op), 1)
        this.offeringtype = newofferingtype
      } else {
        const newofferingtype = this.offeringtype
        newofferingtype.push(op)
        this.offeringtype = newofferingtype
      }
    },
    handleFocusAreaOption(op) {
      if (this.focusarea.indexOf(op) >= 0) {
        const newfocusarea = this.focusarea.splice(this.focusarea.indexOf(op), 1)
        this.focusarea = newfocusarea
      } else {
        const newfocusarea = this.focusarea
        newfocusarea.push(op)
        this.focusarea = newfocusarea
      }
    },
    changestatus(newstate) {
      if (newstate === 1) {
        if (this.accrole !== null) {
          this.formstate = 2
        } else {
          this.formstate = 1
        }
      } else if (newstate === 3) {
        if (this.industry !== '') {
          this.formstate = 4
        } else {
          this.formstate = 3
        }
      } else if (newstate === 5) {
        if (this.customertype !== '' && this.offeringtype !== '') {
          this.formstate = 7
        } else if (this.customertype !== '') {
          this.formstate = 6
        } else {
          this.formstate = 5
        }
      }
    },
    nextstate() {
      if (this.formstate === 1) {
        if (this.accrole === 'Startup') {
          if (this.startupname !== '' && this.designation !== '' && this.about !== '') {
            this.formstate = 3
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Please fill the mentioned fields',
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          }
        } else {
          this.formstate = 2
        }
      } else if (this.formstate === 2) {
        if (this.industry !== '' && this.lifecycle !== '') {
          this.formstate = 3
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please select lifestyle',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    async logoutFromCognito() {
      await Auth.signOut()
    },
    completeProfile() {
      if (this.accrole === 'Startup' || this.accrole === 'Partner') {
        this.$apollo.mutate({
          mutation: gql`mutation addtoorganization {
            insert_users_organizationtable_one(
              object: {
                customer_type: "${this.customertype.join(',')}",
                description: "${this.about}",
                focus_type: "${this.focusarea.join(',')}",
                industry: "${this.industry.join(',')}",
                offerings_type: "${this.offeringtype.join(',')}",
                partially_complete: true,
                startup_stage: "${this.lifecycle.join(',')}",
                title: "${this.startupname}",
                type: "${this.accrole.toLowerCase()}",
                users_associationtables: {
                  data: {
                    role: "${this.accrole.toLowerCase()}",
                    user_id: ${getUserData().id}
                  },
                  on_conflict: {
                    constraint: users_associationtable_pkey
                  }
                }
              },
              on_conflict: {
                constraint: users_organizationtable_pkey
              }
            ) {
              id
            }
          }`,
          update: (store, { data: { insert_users_organizationtable_one } }) => {
            this.$apollo.mutate({
              mutation: gql`mutation updateUserProfile {
                update_users_customuser_by_pk(
                  pk_columns: {id: ${getUserData().id}},
                  _set: {
                    default_startup: ${insert_users_organizationtable_one.id},
                    partially_complete: true,
                  }
                ) {
                  id
                  first_name
                  partially_complete
                  email
                  timezone
                  user_id
                  users_associationtables{
                    id
                    organization_id
                    program_id
                    role
                    designation
                    users_organizationtable {
                      id
                      title
                      domain
                    }
                  }
                }
              }`,
              update: (str, { data: { update_users_customuser_by_pk } }) => {
                if (this.accrole === 'Partner') {
                  this.handleGroupShifting()
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Profile updated successfully',
                      icon: 'CheckIcon',
                      variant: 'success',
                    },
                  })
                  const userData = getUserData()
                  userData.associatedOrgDetails = update_users_customuser_by_pk.users_associationtables
                  localStorage.setItem('userData', JSON.stringify(userData))
                  window.location.href = '/'
                }
              },
            })
          },
        })
      } else {
        this.$apollo.mutate({
          mutation: gql`mutation updateUserProfile {
            update_users_customuser_by_pk(
              pk_columns: {id: ${getUserData().id}},
              _set: {
                customer_type: "${this.customertype.join(',')}",
                description: "${this.about}",
                focus_type: "${this.focusarea.join(',')}",
                industry: "${this.industry.join(',')}",
                offerings_type: "${this.offeringtype.join(',')}",
                partially_complete: true,
                startup_stage: "${this.lifecycle.join(',')}",
                role: "${this.accrole.toLowerCase()}",
                ${this.accrole === 'Mentor' ? 'is_mentor: true' : ''}
                ${this.accrole === 'Student' ? 'is_student: true' : ''}
              }
            ) {
              id
            }
          }`,
          update: (store, { data: { update_users_customuser_by_pk } }) => {
            if (this.accrole === 'Mentor') {
              this.handleGroupShifting()
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: update_users_customuser_by_pk.id ? 'Profile updated successfully' : 'Failed to update',
                  icon: update_users_customuser_by_pk.id ? 'CheckIcon' : 'XIcon',
                  variant: update_users_customuser_by_pk.id ? 'success' : 'danger',
                },
              })
              localStorage.setItem('userData', JSON.stringify(update_users_customuser_by_pk))
              window.location.href = '/'
            }
          },
        })
      }
    },

    async handleGroupShifting() {
      const moveStatus = await axios.post('https://5vbvg1lrph.execute-api.ap-south-1.amazonaws.com/default/make-mentor', {
        userPoolId: 'ap-south-1_GtBnnBtWZ',
        userName: getUserData().user_id,
      })
      if (moveStatus) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Profile updated successfully, please login again to continue.',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

        // Remove userData from localStorage
        localStorage.removeItem('userData')

        // Reset ability
        this.$ability.update(initialAbility)

        // Sign out from AWS Cognito
        this.logoutFromCognito().then(() => {
          // Navigate User back to login
          this.$router.push({ name: 'auth-login' })
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong, contact support.',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      }
    },
    // async loginnext() {
    //   const isExist = await axios.post('https://5vbvg1lrph.execute-api.ap-south-1.amazonaws.com/default/make-mentor', {
    //     userPoolId: 'ap-south-1_GtBnnBtWZ',
    //     userName: this.userEmail,
    //   })
    //   if (JSON.parse(isExist.data.body).length > 0) {
    //     this.formstate = 1
    //   } else {
    //     this.formstate = -1
    //   }
    // },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<style>
  .onboarding-landing{
    width: 100%;
    height: 100vh;
  }
  @media(min-width: 768px){
    .onboarding-card{
      width: 60%;
      height: 80vh;
      overflow-y: auto;
    }
  }
  @media(max-width: 767px){
    .onboarding-card{
      width: 80%;
      height: 80vh;
      overflow-y: auto;
    }
  }
  .dotsholder{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .dotbox{
    width: 25%;
  }

  .dotwrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
  }

  .dot{
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2b2b2b;
    background-color: #e7e7e7;
    font-weight: bolder;
    border-radius: 50%;
    z-index: 2;
    cursor: pointer;
  }

  .dot.active{
    color: #fff;
    background-color: #7367F0;
  }

  .backlineholderl{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .backlinel{
    width: 50%;
    height: 4px;
    background-color: #e7e7e7;
    margin-top: -4%;
  }
  .backlinel.active{
    background-color: #7367F0;
  }

  .backlineholderr{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .backliner{
    width: 50%;
    height: 4px;
    background-color: #e7e7e7;
    margin-top: -4%;
  }
  .backliner.active{
    background-color: #7367F0;
  }

  .role-card{
    border: solid 1px #7367F0;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    color: #7367F0;
    cursor: pointer;
  }

  .role-card.active{
    background-color: #7367F0;
    color: #fff;
  }

  .rol-img{
    display: flex;
    justify-content: center;
  }

  .category-card-inactive{
    background-color: #fff;
    border: solid 1px #7367F0;
    border-radius: 8px;
    cursor: pointer;
    aspect-ratio: 1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bolder;
  }

  .category-card-active{
    background-color: #7367F0;
    border: solid 1px #7367F0;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
    aspect-ratio: 1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bolder;
  }
</style>
